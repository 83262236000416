import JavaScript from "./icons/javascript.png";
import React from "./icons/react.png";
import Next from "./icons/next.png";
import C from "./icons/c.png";
import WebRTC from "./icons/webrtc.png";
import Ruby from "./icons/ruby.png";
import Git from "./icons/git.png";
import Mongo from "./icons/mongo.png";
import Node from "./icons/node.png";
import Rust from "./icons/rust.png";
import SQL from "./icons/sql.png";
import Terraform from "./icons/terraform.png";
import Kubernetes from "./icons/kubernetes.png";
import Typescript from "./icons/Typescript.png";
import Eclipse from "./icons/Eclipse.png";
import CloudArchitect from "./icons/CloudArchitect.png";
import CloudAssosiate from "./icons/CloudAssosiate.png";
import CloudNetwork from "./icons/CloudNetwork.png";
import Scala from "./icons/Scala.png";
import Erlang from "./icons/Erlang.png";
import Go from "./icons/Go.png";

export const frontendSkills = [
  { name: "JavaScript", icon: JavaScript },
  { name: "React", icon: React },
  { name: "Next.js", icon: Next },
  { name: "C", icon: C },
  { name: "WebRTC", icon: WebRTC },
  { name: "Ruby", icon: Ruby },
  { name: "TypeScript", icon: Typescript },
  { name: "Eclipse RCP", icon: Eclipse },
];

export const backendSkills = [
  { name: "Git", icon: Git },
  { name: "Scala", icon: Scala },
  { name: "Go", icon: Go },
  { name: "Erlang", icon: Erlang },
  { name: "MongoDB", icon: Mongo },
  { name: "Node.js", icon: Node },
  { name: "Rust", icon: Rust },
  { name: "SQL", icon: SQL },
  { name: "Terraform", icon: Terraform },
  { name: "Kubernetes", icon: Kubernetes },
];

export const cloudSkills = [
  { name: "GCP", icon: CloudArchitect },
  { name: "AWS", icon: CloudAssosiate },
  { name: "Azure", icon: CloudNetwork },
];
