import React, { useEffect } from 'react';
import * as THREE from 'three';

const TwoLayered3DObject: React.FC = () => {
  useEffect(() => {
    let scene: THREE.Scene, camera: THREE.PerspectiveCamera, renderer: THREE.WebGLRenderer;
    let outerShape: THREE.Mesh, innerShape: THREE.Mesh;

    const init = () => {
      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 2;

      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(750, 700);
      renderer.setClearColor(0x000000, 0);
      document.getElementById('3d-object-container')?.appendChild(renderer.domElement);

      const outerGeometry = new THREE.IcosahedronGeometry(1, 0);
      const outerMaterial = new THREE.MeshBasicMaterial({ color: 0x0099ff, wireframe: true });
      outerShape = new THREE.Mesh(outerGeometry, outerMaterial);
      scene.add(outerShape);

      const innerGeometry = new THREE.IcosahedronGeometry(0.5, 0);
      const innerMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true });
      innerShape = new THREE.Mesh(innerGeometry, innerMaterial);
      scene.add(innerShape);

      animate();
    };

    const animate = () => {
      requestAnimationFrame(animate);
      outerShape.rotation.x += 0.01;
      outerShape.rotation.y += 0.01;
      innerShape.rotation.x += 0.02;
      innerShape.rotation.y += 0.02;
      (innerShape.material as THREE.MeshBasicMaterial).color.setHSL((Date.now() % 360) / 360, 1, 0.5); // Typecast to THREE.MeshBasicMaterial
      renderer.render(scene, camera);
    };

    init();

    return () => {
      document.getElementById('3d-object-container')?.removeChild(renderer.domElement);
    };
  }, []);

  return <div id="3d-object-container" className="absolute top-0 left-0 right-0 bottom-0"></div>;
};

export default TwoLayered3DObject;
