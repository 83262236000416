import React, { useState } from 'react';
import { frontendSkills, backendSkills, cloudSkills } from '../assets/assets';
import SkillCard from './SkillCard';

const SkillSlideShow: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'frontend' | 'backend' | 'cloud'>('frontend');

  const skills = activeTab === 'frontend' ? frontendSkills : (activeTab === 'backend' ? backendSkills : cloudSkills);
  const skillText = activeTab === 'frontend'
    ? 'These are the skills I use for Front End Development. They include technologies such as React, JavaScript, and Next.js, which allow me to build dynamic and interactive web applications.'
    : (activeTab === 'backend'
      ? 'These are the skills I use for Back End Development. They include technologies such as Node.js, MongoDB, and Terraform, which enable me to create robust and scalable server-side applications.'
      : 'These are the skills I use for Cloud Development. They include technologies such as AWS, Azure, and Google Cloud, which allow me to build and manage scalable cloud infrastructure.');

  return (
    <div className="text-center text-white mt-16">
      <h2 className="text-6xl mb-4">About</h2>
      <span className="block w-16 h-2 bg-blue-500 mt-2 mx-auto"></span>
      <h3 className="text-3xl mt-8">Front End, Back End, and Cloud Skills</h3>
      <div className="flex justify-center mt-4">
        <button
          className={`mr-4 px-6 py-2 text-2xl font-bold ${activeTab === 'frontend' ? 'bg-gradient-to-r from-blue-400 to-teal-400 text-white' : 'bg-transparent text-gray-500 border-2 border-gray-500'}`}
          onClick={() => setActiveTab('frontend')}
        >
          Front End
        </button>
        <button
          className={`mr-4 px-6 py-2 text-2xl font-bold ${activeTab === 'backend' ? 'bg-gradient-to-r from-blue-400 to-teal-400 text-white' : 'bg-transparent text-gray-500 border-2 border-gray-500'}`}
          onClick={() => setActiveTab('backend')}
        >
          Back End
        </button>
        <button
          className={`px-6 py-2 text-2xl font-bold ${activeTab === 'cloud' ? 'bg-gradient-to-r from-blue-400 to-teal-400 text-white' : 'bg-transparent text-gray-500 border-2 border-gray-500'}`}
          onClick={() => setActiveTab('cloud')}
        >
          Cloud
        </button>
      </div>
      <p className="mt-8 text-xl max-w-4xl mx-auto">{skillText}</p>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-8">
        {skills.map((skill) => (
          <SkillCard key={skill.name} name={skill.name} icon={skill.icon} />
        ))}
      </div>
    </div>
  );
};

export default SkillSlideShow;
