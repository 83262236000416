import React, { useEffect } from "react";
import * as THREE from "three";
import { FaCheckCircle, FaCalendarAlt, FaHourglassHalf } from "react-icons/fa";

const PoeTrade: React.FC = () => {
  useEffect(() => {
    let scene: THREE.Scene,
      camera: THREE.PerspectiveCamera,
      renderer: THREE.WebGLRenderer;
    let particles: THREE.BufferGeometry,
      particleSystem: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;

    const initParticles = () => {
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x1c1c1c); // Set background color in THREE.js

      camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      camera.position.z = 400;

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      document
        .getElementById("diablo-awakened")
        ?.appendChild(renderer.domElement);

      particles = new THREE.BufferGeometry();
      const particleCount = 800;
      const particlePositions = new Float32Array(particleCount * 3);
      const particleSpeeds = new Float32Array(particleCount * 3);
      for (let i = 0; i < particleCount; i++) {
        particlePositions[i * 3] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 1] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 2] = Math.random() * 1600 - 800;
        particleSpeeds[i * 3] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 1] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 2] = Math.random() * 0.05 - 0.025;
      }
      particles.setAttribute(
        "position",
        new THREE.BufferAttribute(particlePositions, 3)
      );
      particles.setAttribute(
        "speed",
        new THREE.BufferAttribute(particleSpeeds, 3)
      );

      const particleMaterial = new THREE.PointsMaterial({
        color: 0x0099ff,
        size: 3,
        sizeAttenuation: true,
        map: new THREE.TextureLoader().load(
          "https://threejs.org/examples/textures/sprites/disc.png"
        ),
        alphaTest: 0.5,
        transparent: true,
      });
      particleSystem = new THREE.Points(particles, particleMaterial);
      scene.add(particleSystem);

      window.addEventListener("resize", onWindowResizeParticles, false);

      animateParticles();
    };

    const onWindowResizeParticles = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    const animateParticles = () => {
      requestAnimationFrame(animateParticles);

      const particlePositions = particles.attributes.position
        .array as Float32Array;
      const particleSpeeds = particles.attributes.speed.array as Float32Array;
      for (let i = 0; i < particlePositions.length; i += 3) {
        particlePositions[i] += particleSpeeds[i];
        particlePositions[i + 1] += particleSpeeds[i + 1];
        particlePositions[i + 2] += particleSpeeds[i + 2];

        if (particlePositions[i] > 800 || particlePositions[i] < -800)
          particleSpeeds[i] *= -1;
        if (particlePositions[i + 1] > 800 || particlePositions[i + 1] < -800)
          particleSpeeds[i + 1] *= -1;
        if (particlePositions[i + 2] > 800 || particlePositions[i + 2] < -800)
          particleSpeeds[i + 2] *= -1;
      }
      particles.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    };

    initParticles();

    return () => {
      window.removeEventListener("resize", onWindowResizeParticles);
      document
        .getElementById("diablo-awakened")
        ?.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div
      id="diablo-awakened"
      className="section diablo-awakened-section flex flex-col items-center p-10 relative"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <h2 className="text-6xl mb-4 text-white">POE Trade 2</h2>
      <span className="block w-16 h-2 bg-blue-500 mt-2 mx-auto"></span>
      <div className="mt-12 text-white">
      <h3 className="text-3xl mb-4">Important Announcement</h3>
      <p className="mb-8 text-lg">
        Nick and his team have decided to suspend work on Diablo 4 Awakened at this time. After exploring our options with Diablo 4 and due to recent events involving Rod Fergusson and Twitter-related matters, we no longer support the movement surrounding Diablo 4. This is not to say me and my team hate or dislike the game in any way shape or form. We simply decided together we should move towards another area and focus our attention there. Instead, we are focusing our attention on Path of Exile 2 (PoE 2). Moving to PoE 2 was a safer and more strategic option to avoid any conflicts with the existing "Diablo.trade," which remains an outstanding platform for players.
      </p>
        <h3 className="text-3xl mb-4">Roadmap</h3>
        <p className="mb-8 text-lg">
        We are excited to announce that work on PoE Trade 2 has officially begun! This platform aims to revolutionize the trading experience for Path of Exile 2 players. Below is our updated roadmap detailing our development goals:
      </p>
      <div className="w-full flex justify-center mb-8">
        <div className="flex flex-col items-start">
          <div className="flex items-center mb-4">
          <FaHourglassHalf className="text-yellow-500 mr-2" />
            <span className="text-xl">Q1 2025: Initial Concept and Design</span>
          </div>
          <div className="flex items-center mb-4">
            <FaHourglassHalf className="text-yellow-500 mr-2" />
            <span className="text-xl">Q2 2025: Core Development Begins</span>
          </div>
          <div className="flex items-center mb-4">
            <FaHourglassHalf className="text-yellow-500 mr-2" />
            <span className="text-xl">Q3 2025: Prototype and Alpha Testing</span>
          </div>
          <div className="flex items-center mb-4">
            <FaHourglassHalf className="text-yellow-500 mr-2" />
            <span className="text-xl">Q4 2025: Beta Testing Preparation</span>
          </div>
          <div className="flex items-center mb-4">
            <FaCalendarAlt className="text-blue-500 mr-2" />
            <span className="text-xl">2025/6: Wait On The Official Release Of PoE</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default PoeTrade;
