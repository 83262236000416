import React, { useEffect, useState } from 'react';

const Splitter: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<string>('');
  const [isSticky, setIsSticky] = useState<boolean>(false);

  const handleScroll = () => {
    const sections = document.querySelectorAll('.section');
    let current = '';

    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      if (sectionTop <= window.innerHeight / 2 && sectionTop + section.clientHeight >= 0) {
        current = section.getAttribute('id') ?? '';
      }
    });

    setCurrentSection(current);

    const heroSection = document.getElementById('hero');
    if (heroSection) {
      const heroBottom = heroSection.getBoundingClientRect().bottom;
      setIsSticky(heroBottom <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`splitter-container ${isSticky ? 'sticky' : 'initial'}`}>
      <div className="splitter flex justify-end p-0">
        {['About', 'Projects'].map((section) => (
          <button
            key={section}
            className={`mx-6 text-3xl font-bold cursor-pointer ${
              currentSection.toLowerCase() === section.toLowerCase() ? 'text-blue-500 underline' : 'text-white'
            }`}
            onClick={() => document.getElementById(section.toLowerCase())?.scrollIntoView({ behavior: 'smooth' })}
            style={{
              background: 'none',
              border: 'none',
              padding: 0,
              color: currentSection.toLowerCase() === section.toLowerCase() ? '#1e90ff' : 'white',
              textDecoration: currentSection.toLowerCase() === section.toLowerCase() ? 'underline' : 'none',
              cursor: 'pointer',
              outline: 'none'
            }}
          >
            {section}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Splitter;
