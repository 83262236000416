import React from 'react';

const Hero: React.FC = () => {
  return (
    <div className="hero h-screen flex flex-col justify-center items-center text-center text-white relative z-10">
      <h1 className="text-9xl mb-4 font-semibold">
        Hello, I&apos;m <span className="text-blue-300 underline">Nick</span>
      </h1>
      <p className="text-5xl mb-6">A DevOps Engineer and Fullstack Developer</p>
    </div>
  );
};

export default Hero;
