import React from 'react';

interface PortfolioItemProps {
  title: string;
  description: string;
  link: string;
  image: string;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ title, description, link, image }) => {
  return (
    <div className="portfolio-item max-w-sm bg-gray-800 rounded-lg shadow-md overflow-hidden">
      <img src={image} alt={title} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl text-white">{title}</h3>
        <p className="text-gray-400">{description}</p>
        <a href={link} className="text-red-500 hover:underline mt-2 inline-block">View Project</a>
      </div>
    </div>
  );
};

export default PortfolioItem;
