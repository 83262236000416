import React from 'react';
import Hero from '../components/Hero';
import About from './About';
import Splitter from '../components/Splitter';
import Background from '../components/animations/Background';
import Projects from './Projects';

const Home: React.FC = () => {
  return (
    <div>
      <Background />
      <Hero />
      <Splitter />
      <About />
      <Projects />
    </div>
  );
};

export default Home;
