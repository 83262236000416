import React from 'react';
import PortfolioItem from '../components/PortfoilioItem';

const projects = [
  {
    title: 'Diablo Trade',
    description: 'At Diablo.trade, we believe that the heart of the Diablo 4 experience...',
    link: 'https://diablo.trade',
    image: '/assets/images/diablo.png'
  },
  {
    title: 'Voyager Essential',
    description: 'Worked with mainly frontend techs such as React, TS, JS...',
    link: 'https://voyageressential.com',
    image: '/assets/images/voyager.png'
  }
];

const Portfolio: React.FC = () => {
  return (
    <div>
      <div className="projects-section p-6">
        <h2 className="text-3xl text-white mb-4">My Work</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {projects.map((project) => (
            <PortfolioItem key={project.title} {...project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
