import React, { useEffect } from "react";
import * as THREE from "three";
import TwoLayered3DObject from "../components/animations/TwoLayered3dObject";
import SkillSlideShow from "../components/SkillSlideShow";

const About: React.FC = () => {
  useEffect(() => {
    let scene1: THREE.Scene,
      camera1: THREE.PerspectiveCamera,
      renderer1: THREE.WebGLRenderer;
    let particles: THREE.BufferGeometry,
      particleSystem: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;

    const initParticles = () => {
      scene1 = new THREE.Scene();
      scene1.background = new THREE.Color(0x1c1c1c);

      camera1 = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        1000
      );
      camera1.position.z = 400;

      renderer1 = new THREE.WebGLRenderer({ antialias: true });
      renderer1.setSize(window.innerWidth, window.innerHeight);
      document
        .getElementById("about-section")
        ?.appendChild(renderer1.domElement);

      particles = new THREE.BufferGeometry();
      const particleCount = 800;
      const particlePositions = new Float32Array(particleCount * 3);
      const particleSpeeds = new Float32Array(particleCount * 3);
      for (let i = 0; i < particleCount; i++) {
        particlePositions[i * 3] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 1] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 2] = Math.random() * 1600 - 800;
        particleSpeeds[i * 3] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 1] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 2] = Math.random() * 0.05 - 0.025;
      }
      particles.setAttribute(
        "position",
        new THREE.BufferAttribute(particlePositions, 3)
      );
      particles.setAttribute(
        "speed",
        new THREE.BufferAttribute(particleSpeeds, 3)
      );

      const particleMaterial = new THREE.PointsMaterial({
        color: 0x0099ff,
        size: 3,
        sizeAttenuation: true,
        map: new THREE.TextureLoader().load(
          "https://threejs.org/examples/textures/sprites/disc.png"
        ),
        alphaTest: 0.5,
        transparent: true,
      });
      particleSystem = new THREE.Points(particles, particleMaterial);
      scene1.add(particleSystem);

      window.addEventListener("resize", onWindowResizeParticles, false);

      animateParticles();
    };

    const onWindowResizeParticles = () => {
      camera1.aspect = window.innerWidth / window.innerHeight;
      camera1.updateProjectionMatrix();
      renderer1.setSize(window.innerWidth, window.innerHeight);
    };

    const animateParticles = () => {
      requestAnimationFrame(animateParticles);

      const particlePositions = particles.attributes.position
        .array as Float32Array;
      const particleSpeeds = particles.attributes.speed.array as Float32Array;
      for (let i = 0; i < particlePositions.length; i += 3) {
        particlePositions[i] += particleSpeeds[i];
        particlePositions[i + 1] += particleSpeeds[i + 1];
        particlePositions[i + 2] += particleSpeeds[i + 2];

        if (particlePositions[i] > 800 || particlePositions[i] < -800)
          particleSpeeds[i] *= -1;
        if (particlePositions[i + 1] > 800 || particlePositions[i + 1] < -800)
          particleSpeeds[i + 1] *= -1;
        if (particlePositions[i + 2] > 800 || particlePositions[i + 2] < -800)
          particleSpeeds[i + 2] *= -1;
      }
      particles.attributes.position.needsUpdate = true;
      renderer1.render(scene1, camera1);
    };

    initParticles();

    return () => {
      window.removeEventListener("resize", onWindowResizeParticles);
      document
        .getElementById("about-section")
        ?.removeChild(renderer1.domElement);
    };
  }, []);

  return (
    <div
      id="about"
      className="section about-section flex flex-col md:flex-row items-start justify-between p-10 bg-[#1c1c1c] relative"
    >
      <div id="about-section" className="absolute inset-0"></div>
      <div className="about-content relative flex flex-col items-center md:items-center md:w-1/2 z-10 mt-16">
        <div className="absolute top-[-10%] left-0 right-64 mx-auto w-1/2 h-1/2">
          <TwoLayered3DObject />
        </div>
        <div
          className="text-center text-white mt-80"
          style={{
            maxWidth: "400px",
            fontFamily: "Raleway, sans-serif",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          <p className="about-text mt-64">
            Fully committed to the philosophy of life-long learning, I&apos;m a
            full-stack developer with a deep passion for DevOps, cloud
            technologies, coding, music, and gaming. The endless possibilities
            in technology drive my excitement and passion for development. When
            I&apos;m not coding, I enjoy exploring new music, diving into DevOps
            practices, playing video games, and staying updated with the latest
            cloud advancements.
          </p>
        </div>
      </div>
      <div className="md:w-1/2 p-8 z-10 text-center">
        <SkillSlideShow />
      </div>
    </div>
  );
};

export default About;
