import { useEffect } from 'react';
import * as THREE from 'three';

const Background = () => {
  useEffect(() => {
    let scene, camera, renderer, particles, particleSystem, linesMesh, mouse, lines;

    const init = () => {
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x1c1c1c); // Set a slightly lighter background color

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 400;

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      document.body.appendChild(renderer.domElement);

      particles = new THREE.BufferGeometry();
      const particleCount = 800;
      const particlePositions = new Float32Array(particleCount * 3);
      const particleSpeeds = new Float32Array(particleCount * 3);

      for (let i = 0; i < particleCount; i++) {
        particlePositions[i * 3] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 1] = Math.random() * 1600 - 800;
        particlePositions[i * 3 + 2] = Math.random() * 1600 - 800;

        particleSpeeds[i * 3] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 1] = Math.random() * 0.05 - 0.025;
        particleSpeeds[i * 3 + 2] = Math.random() * 0.05 - 0.025;
      }
      
      particles.setAttribute('position', new THREE.BufferAttribute(particlePositions, 3));
      particles.setAttribute('speed', new THREE.BufferAttribute(particleSpeeds, 3));

      const particleMaterial = new THREE.PointsMaterial({
        color: 0x0099ff,
        size: 3,
        sizeAttenuation: true,
        map: new THREE.TextureLoader().load('https://threejs.org/examples/textures/sprites/disc.png'),
        alphaTest: 0.5,
        transparent: true,
      });
      particleSystem = new THREE.Points(particles, particleMaterial);
      scene.add(particleSystem);

      const lineMaterial = new THREE.LineBasicMaterial({ color: 0x0099ff, opacity: 0.5, transparent: true });
      lines = new THREE.BufferGeometry();
      lines.setAttribute('position', new THREE.BufferAttribute(new Float32Array(particleCount * 3 * 2), 3));
      linesMesh = new THREE.LineSegments(lines, lineMaterial);
      scene.add(linesMesh);

      mouse = new THREE.Vector2(-1000, -1000);
      document.addEventListener('mousemove', onMouseMove, false);
      window.addEventListener('resize', onWindowResize, false);

      animate();
    };

    const onMouseMove = (event) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      const newParticlePositions = particles.attributes.position.array;
      const newParticleSpeeds = particles.attributes.speed.array;
      const index = Math.floor(Math.random() * particles.attributes.position.count) * 3;
      newParticlePositions[index] = (mouse.x * 800);
      newParticlePositions[index + 1] = (mouse.y * 800);
      newParticlePositions[index + 2] = Math.random() * 800 - 400;
      newParticleSpeeds[index] = Math.random() * 0.05 - 0.025;
      newParticleSpeeds[index + 1] = Math.random() * 0.05 - 0.025;
      newParticleSpeeds[index + 2] = Math.random() * 0.05 - 0.025;

      const removeIndex = Math.floor(Math.random() * particles.attributes.position.count) * 3;
      newParticlePositions[removeIndex] = Math.random() * 1600 - 800;
      newParticlePositions[removeIndex + 1] = Math.random() * 1600 - 800;
      newParticlePositions[removeIndex + 2] = Math.random() * 1600 - 800;
    };

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    const animate = () => {
      requestAnimationFrame(animate);

      const particlePositions = particles.attributes.position.array;
      const particleSpeeds = particles.attributes.speed.array;

      for (let i = 0; i < particlePositions.length; i += 3) {
        particlePositions[i] += particleSpeeds[i];
        particlePositions[i + 1] += particleSpeeds[i + 1];
        particlePositions[i + 2] += particleSpeeds[i + 2];

        if (particlePositions[i] > 800 || particlePositions[i] < -800) particleSpeeds[i] *= -1;
        if (particlePositions[i + 1] > 800 || particlePositions[i + 1] < -800) particleSpeeds[i + 1] *= -1;
        if (particlePositions[i + 2] > 800 || particlePositions[i + 2] < -800) particleSpeeds[i + 2] *= -1;

        const dx = (mouse.x * 800) - particlePositions[i];
        const dy = (mouse.y * 800) - particlePositions[i + 1];
        const distance = Math.sqrt(dx * dx + dy * dy);
        const maxDistance = 100;
        if (distance < maxDistance) {
          const force = (maxDistance - distance) / maxDistance;
          particlePositions[i] -= dx * force * 0.05;
          particlePositions[i + 1] -= dy * force * 0.05;
        }
      }
      particles.attributes.position.needsUpdate = true;

      const linePositions = lines.attributes.position.array;
      let index = 0;
      for (let i = 0; i < particlePositions.length; i += 3) {
        for (let j = i + 3; j < particlePositions.length; j += 3) {
          const dx = particlePositions[i] - particlePositions[j];
          const dy = particlePositions[i + 1] - particlePositions[j + 1];
          const dz = particlePositions[i + 2] - particlePositions[j + 2];
          const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
          if (distance < 75) {
            linePositions[index++] = particlePositions[i];
            linePositions[index++] = particlePositions[i + 1];
            linePositions[index++] = particlePositions[i + 2];
            linePositions[index++] = particlePositions[j];
            linePositions[index++] = particlePositions[j + 1];
            linePositions[index++] = particlePositions[j + 2];
          }
        }
      }
      while (index < linePositions.length) {
        linePositions[index++] = 0;
      }
      lines.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    };

    init();

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', onWindowResize);
      document.body.removeChild(renderer.domElement);
    };
  }, []);

  return null;
};

export default Background;
