import React from 'react';

interface SkillCardProps {
  name: string;
  icon: string;
}

const SkillCard: React.FC<SkillCardProps> = ({ name, icon }) => {
  return (
    <div className="skill-icon text-center border-gradient" style={{ border: '2px solid', borderRadius: '8px' }}>
      <img src={icon} alt={name} className="w-12 h-12 mx-auto" />
      <p className="mt-2 text-white">{name}</p>
    </div>
  );
};

export default SkillCard;
